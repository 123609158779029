<template>
    <div>
        <div class="card">
            <div class="fl-te-c">
                <h4 class="mb-3"><span class="text-uppercase">{{ mediaType }}</span> DETAILS</h4>
                <btn text="Back" @click="$router.go(-1)" size="sm"/>
            </div>

            <edit-news :media-type="mediaType" v-if="details" :initial-data="details"/>
            <p v-else class="text-center p-3">No data available.</p>
        </div>
        <news-image @success="loadDetails" :news="id" :details="details.news_images"/>
    </div>
</template>

<script>
import NewsImage from './MediaImageListing';
import axios from 'secure-axios';
import urls from '@/data/cms-urls';
import EditNews from './EditMedia';

export default {
    name       : 'DetailsPage',
    components : { EditNews, NewsImage },
    data () {
        return {
            mediaType : this.$route.params.media_type,
            loading   : false,
            details   : '',
            id        : this.$route.params.id
        };
    },
    mounted () {
        this.loadDetails();
    },
    watch : {
        '$route.params.media_type' : {
            handler () {
                this.mediaType = this.$route.params.media_type;
            }
        }
    },
    methods : {
        async loadDetails () {
            this.loading = true;
            let detailsUrl;

            if (this.mediaType === 'news') {
                detailsUrl = urls.cms.media.news.details;
            } else {
                detailsUrl = urls.cms.media.blog.details;
            }
            const response = await axios.form(detailsUrl, { id : this.id });
            const json = response.data;
            if (json.error === false) {
                this.details = json.data;
                this.details.category = json.data.category.id;
            } else {
                this.$notify('Could not load data, Please try again later', 'Error', {
                    type : 'danger'
                });
            }
            this.loading = false;
        }
    }
};
</script>

<style scoped>

</style>
